import CustomTokenAuth from '../components/Auth/CustomTokenAuth';
import PageBase from '../components/PageLayout/PageBase';

function Auth() {
  return (
    <PageBase pageTitle="Authenticating...">
      <CustomTokenAuth />
    </PageBase>
  );
}

export default Auth;
