import HelmetTitle from '../../components/HelmetTitle';
import useAppLanguage from '../../hooks/useAppLanguage';
import React from 'react';
import Head from 'next/head';

interface PageBaseProp extends React.PropsWithChildren {
  pageTitle: string;
  appName?: string; // Optional page title is to add additional title. e.g. pageTitle - Title
}

const PageBase: React.FC<PageBaseProp> = ({
  pageTitle,
  appName = 'Wonderschool',
  children,
}) => {
  const { translate } = useAppLanguage();

  return (
    <>
      <Head>
        <meta name="viewport" content="width=device-width, initial-scale=1.0, maximum-scale=1.0" />
        <title>Wonderschool</title>
      </Head>
      <HelmetTitle
        pageTitle={translate(pageTitle)}
        appName={translate(appName)}
      />
      {children}
    </>
  );
};

export default PageBase;
